:root {
    --white-color: #fff;
    --text-color: #222;
    --label-color: #B9B9B9;
    --accent-color: #2269F4;
    --tag-search-bg-color: #F8F8F8;
    --line-color: #E0E0E0;
    --dark-label-color: #979797;
    --tooltip-bg-color: #57738F;
    --footer-bg-color: #F7F7F7;
    --accent-bg-hover-color: #A4C4FF;
    --accent-bg-color: #D4E3FF;
  }