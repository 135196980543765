@import '../../vars.scss';
@import '../../fonts.scss';

.wrapper{
    display: flex;
    justify-content: center;
}

.redaction{
    &__container{
        width: 930px;
        margin-top: 40px;
        margin-bottom: 100px;
        padding-left: 10px;
        padding-right: 10px;
    }
    &__title{
        margin-bottom: 40px;
    }
    &__gap{
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0px 30px;
        margin-bottom: 40px;
        padding-bottom: 40px;
        &__item{
            width: 290px;
            position: relative;
            &__title{
                margin-bottom: 5px;
            }
            &__text{
                @include text-regular;
            }
        }
        &__line{
            width: 100%;
            height: 1px;
            background-color: var(--line-color);
            grid-column-start: 1;
            grid-column-end: 4;
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }
    &__gap:last-child{
        margin-bottom: 0px;
        border-bottom: none;
        padding-bottom: 0px;
    }
}

@media (max-width: 959px) {
    .redaction{
        &__gap{
            grid-template-columns: 1fr 1fr;
            gap: 30px 30px;
            &__item{
                margin-bottom: 0px;
            }
            &__item:last-child{
                margin-bottom: 0px;
            }
            &__line{
                grid-column-start: 1;
                grid-column-end: 3;
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
    }
}

@media (max-width: 639px) {
    .redaction__container{
        margin-bottom: 80px;
    }
    .redaction__gap{
        grid-template-columns: 1fr;
        gap: 20px 30px;
        margin-bottom: 30px;
        padding-bottom: 30px;
    }
    .redaction__gap__item{
        width: 100%;
    }
    .redaction__gap__line{
        grid-column-start: 1;
        grid-column-end: 2;
    }
}