@import '../../../vars.scss';
@import '../../../fonts.scss';

.reforms {
    padding: 24px 30px 100px;
    max-width: 1440px;
    margin: 0 auto;
    &__card {
        &_content {
            display: flex;
            gap: 32px;
            max-width: calc(100% - 100px);
            margin: -49px auto 0;
            height: 380px;
        }
        &_features {
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            &_slider {
                min-height: 336px;
                overflow: hidden;
                height: fit-content;
                width: calc(100% + 20px);
                display: flex;
                flex-direction: column;
                gap: 10px;
                // overflow-y: auto;
                scrollbar-color: transparent;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    width: 0;
                }
                & .swiper-wrapper {
                    width: calc(100% - 20px);
                    margin: 0 auto;
                }
                & .swiper-slide {
                    height: fit-content;
                }
            }
            &_btn_next {
                position: absolute;
                bottom: -12px;
                left: 50%;
                transform: translateX(-50%) rotate(90deg);
                z-index: 2;
                margin-left: 17px;
            }
            &_slide {
                width: calc(50% - 5px);
                flex-shrink: 0;
                height: 68px;
                background: #FFFFFF;
                border: 1px solid #F2F2F2;
                box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
                border-radius: 12px;
                display: flex;
                align-items: center;
                gap: 12px;
                padding: 0 24px;
            }
            &_icon {
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                line-height: 44px;
                color: #222222;
                margin-top: -8px;
                flex-shrink: 0;
            }
            &_title {
                font-weight: 600;
                font-size: 32px;
                line-height: 44px;
                color: #5B99FC;
                white-space: nowrap;
                width: fit-content;
                min-width: 120px;
                flex-shrink: 0;
            }
            &_text {
                @include text-regular
            }
        }
        &_articles {
            width: calc(50% - 16px);
            position: relative;
            &_title {
                @include label-small;
                margin-bottom: 15px;
            }
            &_list {
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 100%;
            }
        }
        &_article {
            &_wrapper {
                width: calc(100%);
                padding-right: 9px;
                max-height: 340px;
                overflow-y: auto;
                scrollbar-color: transparent var(--accent-bg-color);
                scrollbar-width: 8px;
                &::-webkit-scrollbar {
                    width: 8px;
                }
                &::-webkit-scrollbar-track {
                    background: transparent;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: var(--accent-bg-color);
                    border-radius: 8px;
                }
            }
            width: calc(50% - 20px);
            background: #FFFFFF;
            border: 1px solid #F2F2F2;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
            border-radius: 12px;
            padding: 10px 9px 10px 24px;
            position: absolute;
            right: 0;
            top: 0;
            &_date {
                margin-top: 8px;
                @include label-small;
            }
            transition: all 200ms;
            cursor: pointer;
            &:hover {
                .reforms__card_article_title {
                    opacity: 0.8;
                }
            }
            &_title {
                &_text {
                    @include text-regular;
                    font-weight: 500;
                    line-height: 24px;
                }
                display: flex;
                align-items: flex-start;
                width: 100%;
                gap: 15px;
                transition: all 200ms;
                &_btn {
                    margin-left: auto;
                    transition: all 200ms;
                }
            }
            &_content {
                margin-top: 8px;
                font-size: 14px;
                display: flex;
                flex-direction: column;
                gap: 5px;
                max-height: none;
                overflow: hidden;
                transition: all 200ms;
                cursor: default;
                width: calc(100%);
                z-index: 2;
                & figure {
                    max-width: 100%;
                    width: 100%;
                }
                & .img-container {
                    max-width: 100%;
                    width: 100%;
                }
            }
            &.closed {
                min-height: 68px;    
                height: 100%;          
                & .reforms__card_article_title_text {
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                }
                .reforms__card_article_content {
                    margin-top: 0;
                    max-height: 0;
                    overflow: hidden;
                }
                .reforms__card_article_title_btn {
                    transform: rotate(180deg);
                }
            }
        }
    }  
    &_article__numbered_list li,
    &_article__bulleted_list li {
        margin-bottom: 0;
    }
    &__empty_articles {
        background: #FFFFFF;
        border: 1px solid #F2F2F2;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        // height: 68px;
        padding: 20px 24px;
        width: 100%;
        height: calc(100%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &_text {
            @include text-regular;
            text-align: center;
            margin: 15px auto 0;
            font-size: 18px;
        }
        & img {
            max-width: 290px;
            width: 80%;
            margin: 0 auto;
            height: auto;
        }
    }
}
.rr__list {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    gap: 80px;
}

.reform__card_slide {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
}
.opened_slide {
    z-index: 2;
}
.reforms__empty_articles_desc {
    position: absolute;
    right: 0;
    width: calc(50% - 10px);
}

@media screen and (max-width: 1150px) {
    .reforms {
        &__card_features_title {
            min-width: auto;
        }
    }
}

@media screen and (max-width: 980px) {
    .reforms {
        &__card_content {
            max-width: calc(100% - 30px);
        }
        &__card_features_slide {
            flex-wrap: wrap;
            height: auto;
            padding: 12px 24px;
            row-gap: 2px;
        }
        &__card_features_text {
            width: 100%;
        }
    }
}
.articles_mobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .rr__list {
        gap: 60px;
        margin-top: 60px;
    }
    .reform__card_slide {
        flex-direction: column;
    }
    .reforms__card_article {
        position: static;
        width: 100%;
    }
    .reforms__card_features_slide {
        width: 100%;
    }
    .reforms__card_features_slider {
        min-height: auto;
    }
    .articles_mobile {
        display: block;
    }
    .reforms {
        padding: 33px 15px 100px;
        &__card {
            &_content {
                display: flex;
                gap: 10px;
                flex-direction: column;
                max-width: 100%;
                margin: 20px 0 0;
                height: auto;
                // overflow-y: auto;
                // scrollbar-color: transparent var(--accent-bg-color);
                // scrollbar-width: 8px;
                // &::-webkit-scrollbar {
                //     width: 8px;
                // }
                // &::-webkit-scrollbar-track {
                //     background: transparent;
                // }
                // &::-webkit-scrollbar-thumb {
                //     background-color: var(--accent-bg-color);
                //     border-radius: 8px;
                // }
            }
            &_features {
                width: 100%;
                &_slider {
                    width: calc(100% - 15px);
                    margin-left: 5px;
                    height: auto;
                    overflow: visible;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    & .swiper-wrapper {
                        width: 100%;
                        margin: 0 auto;
                    }
                    & .swiper-slide {
                        height: fit-content;
                    }
                }
                &_btn_next {
                    display: none;
                }
            }
            &_articles {
                width: 100%;
                height: fit-content;
                // max-height: 380px;
                &_list {
                    width: calc(100% - 15px);
                    margin-left: 5px;
                    &:last-child {
                        margin-bottom: 15px;
                    }
                }
                & ul {
                    max-height: none;
                }
            }
            &_article {
                max-height: none;
                &_content {
                    & figure {
                        max-width: 100%;
                    }
                    & .img-container {
                        max-width: 100%;
                    }
                }
            }
        }
    }    
}