@import '../../vars.scss';
@import '../../fonts.scss';

.img-container{
    width: 100%;
    padding-top: 56%;
    height: auto;
    position: relative;
    border-radius: 12px;
}
.img-item,
.img-container video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    position: absolute;
    top: 0;
    left: 0;
}
.img-description{
    @include label-big;
    font-size: 14px;
    margin-top: 12px;
    // margin-bottom: 20px;
    max-width: 600px;
}

figure {
    width: 100%;
}

@media (max-width: 639px) {
    .img-container{
        width: 290px;
        height: 175px;
    }
    .img-description{
        max-width: 290px;
    }
}