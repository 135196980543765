@import '../../vars.scss';
@import '../../fonts.scss';

.wrapper{
    display: flex;
    justify-content: center;
}

.notfound{
    &__container{
        width: 770px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 80px;
        margin-bottom: 160px;
    }
    &__title{
        text-align: center;
        margin-bottom: 20px;
    }
    &__text{
        @include text-regular;
        margin-bottom: 20px;
    }
    &__home-link{
        @include link-medium;
    }
}

@media screen and (max-width: 639px) {
    .notfound{
        &__container{
            width: 290px;
            margin-top: 60px;
            margin-bottom: 80px;
        }
        &__title{
            width: 182px;
            word-wrap: normal;
            margin-bottom: 15px;
        }
        &__text {
            text-align: center;
            margin-bottom: 15px;
        }
    }
}