@import '../../../fonts.scss';

.timeline {
    flex-shrink: 0;

    &__empty {
        @include text-regular;
        color: var(--accent-color);
        text-align: center;
        margin-top: 20px;
    }

    &__contract {
        margin-bottom: 20px;
        width: 290px;
        display: block;
        transition: all 200ms;
        cursor: pointer;
        &:nth-child(1) {
            margin-bottom: 10px;
            cursor: default;
        }
        &:hover {
            opacity: 0.8;
        }
        &:nth-child(1):hover {
            opacity: 1;
        }
    }

    &__wrapper {
        position: sticky;
        top: 173px;
        width: 290px;
        height: calc(100vh - 200px);
        transition: all 200ms;

        &_active {
            top: 100px;
            height: calc(100vh - 150px);
        }
    }

    &__news {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 10px;
        max-height: calc(100% - 42px);
        overflow: scroll;
        scrollbar-width: 8px;
        scrollbar-color: var(--accent-bg-color) #fff;

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--accent-bg-hover-color);
            border-radius: 9px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: var(--accent-bg-color);
        }
    }

    &__head_wrapper {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    &__date_select {
        &_wrapper {
            display: flex;
            gap: 12px;
            align-items: flex-end;
            margin-left: 10px;
        }

        &_item {
            cursor: pointer;
            transition: all 200ms;
            @include text-regular;
            margin-top: 7px;

            &:hover {
                opacity: 0.8;
                color: var(--accent-color)
            }
        }
    }
}

// 1260+
@media screen and (max-width: 1440px) {
    .timeline {
        &__contract {
            width: 255px;
        }

        &__wrapper {
            width: 255px;
        }
    }
}

// 980+
@media screen and (max-width: 1260px) {
    .timeline {
        flex-shrink: 0;
        width: 100%;
        display: none;

        &__date_select {
            &_wrapper {
                display: flex;
                gap: 12px;
                margin-left: 0;
            }

            &_item {
                cursor: pointer;
                transition: all 200ms;
                @include text-regular;
                margin-top: 7px;
                font-size: 20px;
                margin-bottom: 10px;

                &:hover {
                    color: #222;
                }
            }
        }

        &__contract {
            display: none;
        }

        h4 {
            display: none;
        }

        &__wrapper {
            position: inherit;
            width: 100%;
            overflow: visible;
            height: auto;

            &_active {
                height: auto;
            }
        }

        &__news {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 10px;
            max-height: 100%;
            overflow: scroll;

            & .card {
                width: 100%;
            }
        }
    }
}