@import '../../fonts.scss';

.socials_login {
    display: flex;
    gap: 10px;

    &_item {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--line-color);
        border-radius: 5px;
        width: 44px;
        height: 44px;
        transition: all 200ms;

        & svg {
            width: 27px;
            height: 27px;
        }

        &:hover {
            opacity: 0.8;
        }
    }
}

.header {
    width: 100%;

    &__mySPbTag {
        margin: 0 auto;
        background: var(--accent-color);
        padding: 7px 10px;
        border-radius: 20px;
        color: #fff;
        transition: all 200ms;
        white-space: nowrap;
        &:hover {
            color: #fff;
            opacity: 0.8;
        }

        @media screen and (max-width: 520px) {
            font-size: 12px;
        }

        @media screen and (max-width: 480px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 130px;
            height: 40px;
            padding: 0;
            border-radius: 8px;
            white-space: normal;
            text-align: center;
            font-size: 10px;
            margin: 0 auto;
        }

        @media screen and (max-width: 420px) {
            // margin-left: 10px;
        }
        @media screen and (max-width: 400px) {
            font-size: 9px;
            width: 120px;
            height: 36px;
        }
    }

    &__empty_space {
        height: 143px;
    }

    &__wc {
        display: flex;
        gap: 20px;
        align-items: flex-end;
        @include text-regular;
        // margin-right: auto;
        margin-left: 30px;
        // margin-top: -6px;

        &_block {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &_title {
            color: var(--accent-color);
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 5px;
        }

        @media screen and (max-width: 680px) {
            margin-left: 5px;
        }

        @media screen and (max-width: 785px) {
            display: none;
        }
    }

    &__weather {
        font-size: 24px;
        color: var(--accent-color);
        display: flex;
        align-items: center;

        & img {
            width: 35px;
            height: 35px;
            object-fit: contain;
        }

        &_num {
            color: var(--accent-color)
        }
    }

    &__traffic {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border-width: 3px;
        border-style: solid;
        @include text-regular;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__currency {
        color: var(--accent-color);
        font-size: 14px;
        line-height: 20px;
    }

    &__wrapper {
        border-bottom: 1px solid var(--line-color);
        transition: all 300ms;
        max-height: 147px;
        width: 100%;
        position: fixed;
        top: 0;
        background: #fff;
        z-index: 100;

        &_scrolled {
            max-height: 80px;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);

            .header__content {
                padding-top: 12px;
            }

            .header__tags_row {
                opacity: 0;
                pointer-events: none;
                position: absolute;
            }
        }
    }

    &__content {
        max-width: 1440px;
        margin: 0 auto;
        padding: 16px 30px;
        transition: all 200ms;
        position: relative;
    }

    &__logo_row {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
    }

    &__logo {
        height: 44px;
        display: inline-block;

        @media screen and (max-width: 641px) {
            // margin-right: auto;
        }

        a {
            cursor: pointer;
            transition: all 200ms;
            display: flex;
            align-items: center;
            gap: 15px;
            flex-shrink: 0;

            & svg {
                flex-shrink: 0;
                z-index: 2;
                background-color: #fff;
                border-top-right-radius: 22px;
                border-bottom-right-radius: 22px;
                padding-left: 20px;
                margin-left: -20px;
                box-sizing: content-box;
            }
        }

        a:hover {
            cursor: pointer;
            opacity: 0.8;
        }

        &_text {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: var(--accent-color);
            flex-shrink: 0;
            white-space: nowrap;
            z-index: 1;

            animation-duration: 7s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        background: #fff;
        z-index: 2;
    }

    &__login {
        width: 113px;
        height: 44px;
        background: var(--accent-color);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        cursor: pointer;
        transition: all 200ms;

        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: var(--white-color);

        &:hover,
        &:active {
            background: var(--tooltip-bg-color);
            color: #fff;
        }

        &:focus {
            color: #fff;
        }
    }

    &__profile {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 200ms;

        &:hover {
            opacity: 0.9;
        }

        &_avatar_wrapper {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &_name {
            @include text-regular;
            margin: 0 2px 0 8px;
            white-space: nowrap;
            max-width: 100px;
            text-overflow: ellipsis;
            overflow: hidden;

            @media screen and (max-width: 1200px) {
                display: none;
            }
        }

        &_actions_btn {
            width: 20px;
            height: 20px;
            transition: all 200ms;
            display: flex;
            align-items: center;
            justify-content: center;

            &_active {
                transform: rotate(180deg);

                & path {
                    fill: var(--accent-color);
                }
            }
        }

        &_actions {
            background-color: #fff;
            display: flex;
            flex-direction: column;
            width: 230px;
            border: 1px solid #B9B9B9;
            border-radius: 8px;
            padding: 8px 0;
            transition: all 200ms;
            opacity: 0;
            pointer-events: none;
            position: fixed;
            margin-top: 8px;
            margin-left: -58px;
            z-index: 130;

            &_active {
                opacity: 1;
                pointer-events: all;
            }

            & a,
            & button {
                padding: 12px 12px 12px 16px;
                height: 44px;
                display: flex;
                align-items: center;
                cursor: pointer;
                transition: all 200ms;

                &:hover,
                &:focus {
                    background: var(--accent-bg-color);
                }
            }

            & a {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: var(--text-color);
            }

            & button {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #FF2626;
            }

            @media screen and (max-width: 1200px) {
                margin-left: -158px;
            }
        }
    }

    &__tags_row {
        margin-top: 20px;
        display: flex;
        align-items: center;
        transition: all 200ms;
    }

    &__company {
        font-weight: 400;
        font-size: 32px;
        line-height: 1;
        text-transform: uppercase;
        color: var(--accent-color);
        margin-right: 30px;
    }

    &__tags {
        display: flex;
        gap: 16px;
        border-radius: 30px;
        margin-bottom: 0;
        max-width: 100%;
    }

    &__tag {
        padding: 8px 16px;
        width: max-content;
        height: 36px;
        border-radius: 60px;
        @include tag;
        @include clickable;
        border: 1px solid var(--tag-search-bg-color);

        &.active {
            background: var(--accent-bg-color);
            color: var(--accent-color);
        }
    }

    &__tag_nav_btn_prev {
        margin-right: 10px;
        cursor: pointer;
    }

    &__tag_nav_btn_next {
        margin-left: 10px;
        cursor: pointer;
    }

    &__tag_nav_btn_prev.disabled_btn,
    &__tag_nav_btn_next.disabled_btn {
        opacity: 0.4;

        &:hover {
            background: var(--tag-search-bg-color);
            color: var(--text-color);
            border: 1px solid var(--tag-search-bg-color);

            & path {
                fill: #000
            }
        }
    }
}

nav {
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-snap-type: x proximity;

    &::-webkit-scrollbar {
        display: none;
    }
}

.header__wrapper_scrolled .article__progressbar {
    margin-top: -5px;
}

.article {
    &__progressbar {
        height: 2px;
        width: 100%;
        background: #fff;

        &_fill {
            height: 100%;
            width: 50%;
            background-color: var(--accent-color);
        }
    }
}

// 1260+
@media screen and (max-width: 1440px) {
    .header {
        &__content {
            padding: 16px 20px;
        }
        &__wc {
            margin-left: 10px;
        }
    }
}

// 640+
@media screen and (max-width: 980px) {
    .header {
        &__company {
            display: none;
        }

        &__content {
            padding: 16px 15px;
        }

        &__logo {
            & svg {
                width: 120px;
                padding-left: 10px !important;
            }

            &_text {
                animation-name: logoAnimate;
                position: absolute;
            }
        }

        &__tags {
            max-width: none;
            width: fit-content;
        }
    }
}

// 320+
@media screen and (max-width: 640px) {
    .header {
        &__empty_space {
            height: 124px;
        }

        &__login {
            width: 44px;

            &_text {
                display: none;
            }
        }

        &__content {
            padding: 12px 15px;
        }

        &__tags {
            gap: 12px;
        }

        &__logo {
            &_text {
                animation-name: logoAnimateMobile;
            }
        }

        &__actions {
            padding-right: 20px;
            margin-right: -20px;
        }
    }
}


.header {
    &__login_form {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        max-height: 100vh;
        overflow-y: auto;

        &_active {
            background-color: rgba(0, 0, 0, 0.552);
            opacity: 1;
            pointer-events: all;
            padding: 0 10px;
        }

        &_content {
            background-color: #fff;
            width: 400px;
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 40px;
            position: relative;
            border-radius: 20px;
            margin: 15px 0;
        }

        &_form {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;
            margin-top: 12px;

            & fieldset {
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 100%;
            }
        }

        &_close {
            position: absolute;
            top: -40px;
            right: -40px;
            cursor: pointer;
            transition: all 200ms;

            &:hover {
                opacity: 0.8;
            }
        }

        &_title {
            text-align: center;

            &.exit {
                margin-bottom: 20px;
            }
        }

        &_input {
            padding: 0px 16px;
            width: 100%;
            height: 44px;
            background: #FFFFFF;
            border: 1px solid var(--line-color);
            border-radius: 8px;
            @include text-regular;
            transition: all 200ms;

            &_error {
                border-color: red;
            }

            &::placeholder {
                @include label-big;
            }

            &:hover {
                opacity: 0.8;
                border-color: var(--dark-label-color)
            }

            &:focus {
                border-color: var(--accent-color);
            }
        }

        &_textarea {
            height: 130px;
            resize: none;
            padding-top: 10px;
        }

        &_checkbox_label {
            display: flex;
            gap: 15px;
            cursor: pointer;

            &:hover {
                & .fake_checkbox {
                    opacity: 0.8;
                }
            }

            & input {
                display: none;
            }

            & .fake_checkbox {
                flex-shrink: 0;
                width: 25px;
                height: 25px;
                border: 1px solid var(--line-color);
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 200ms;

                & svg {
                    width: 15px;
                    height: 15px;
                }

                &_error {
                    border-color: red;
                }
            }

            & p {
                margin-bottom: 0;
            }

            & input:checked+.fake_checkbox {
                background-color: var(--accent-color);
            }
        }

        &_link {
            color: var(--accent-color);
        }

        &_submit {
            background: var(--accent-color);
            color: #fff;
            transition: all 200ms;

            &:hover,
            &:active {
                background: var(--tooltip-bg-color);
            }
        }

        &_change_type_btn {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--accent-color);
            margin-top: 20px;
            transition: all 200ms;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }

        &_forgot {
            @include label-small;
            color: var(--accent-color);
            align-self: flex-start;
            margin-top: -8px;
            transition: all 200ms;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }

            &_text {
                @include label-small;
                color: var(--text-color);
                margin-bottom: -8px;
            }
        }

        &_confirm {
            &_text {
                @include text-regular;
                color: var(--text-color);
                text-align: center;
            }
        }

        &_error {
            @include text-regular;
            font-size: 14px;
            text-align: center;
            color: red;

            &_psw {
                @include text-regular;
                line-height: 1.3;
                font-size: 14px;
                text-align: center;
                color: red;
                margin: -10px 0;
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .header__login_form_close {
        top: 15px;
        right: 15px;
    }

    .header__login_form_input {
        font-size: 16px;

        &::placeholder {
            font-size: 16px;
        }
    }
}

@media screen and (max-height: 670px) {
    .signup {
        align-items: flex-start;

        .header__login_form_close {
            top: 15px;
            right: 15px;
        }
    }
}

@media screen and (max-height: 580px) {
    .header__login_form {
        align-items: flex-start;
    }
}

@keyframes logoAnimate {
    from {
        left: calc(100% - 503px);
    }

    to {
        left: -285px;
    }
}

@keyframes logoAnimateMobile {
    from {
        left: calc(100% - 100px);
    }

    to {
        left: -285px;
    }
}