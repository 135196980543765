@import '../../../fonts.scss';

.container {
    max-width: 1440px;
    margin: 0 auto;
}

.mainpage {
    &__contract {
        display: none;
    }

    &__main_slider {
        width: 100%;
    }

    &__main_news {
        position: relative;
    }

    &__main_prev {
        display: none;
        position: absolute;
        top: calc(100% - 60px);
        right: 85px;
        transform: translateY(-50%) rotate(180deg) !important;
        z-index: 2;
    }

    &__main_next {
        position: absolute;
        top: calc(100% - 60px);
        right: 30px;
        transform: translateY(-50%);
        z-index: 2;
    }

    &__content {
        display: flex;
        gap: 40px;
        padding: 40px 30px 100px;
    }

    main {
        width: calc(100% - 330px);
    }

    &__type_news_content {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    &__type_switch {
        display: none;
    }

    &__title {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
    }

    &__slider_nav {
        display: flex;
        gap: 8px;

        &_btn {
            @include clickable;
            cursor: pointer;
            width: 44px;
            height: 44px;
            border-radius: 8px;

            &_prev {
                transform: rotate(180deg);
            }

            &.swiper-button-disabled {
                path {
                    fill: var(--label-color)
                }

                background: var(--tag-search-bg-color);
                transition: all 200ms;
                color: var(--text-color);

                &:hover,
                &:active {
                    background: var(--tag-search-bg-color);
                    opacity: 1;

                    & path {
                        fill: var(--label-color)
                    }
                }

                &:focus {
                    background: var(--tag-search-bg-color);
                    border: none;

                    & path {
                        fill: var(--label-color)
                    }
                }
            }

        }
    }

    &__bordered_block {
        padding-bottom: 40px;
        border-bottom: 1px solid var(--line-color);
    }

    .swiper-slide {
        width: fit-content;
    }

    &__actual {
        width: 100%;
    }

    &__actual_slider {
        max-width: 100%;
    }

    &__rr {
        display: flex;
        gap: 24px;

        &_card {
            width: calc(50% - 12px);
            height: 270px;
            padding: 32px;
            display: flex;
            flex-direction: column;
            background: #F5F5F5;
            border-radius: 20px;
            position: relative;

            &_bg {
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: 1;
            }

            &_text {
                @include text-regular;
                margin-top: 10px;
                z-index: 2;
            }

            &_title {
                z-index: 2;
                line-height: 1.1;
            }

            &_link {
                @include text-regular;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                background: var(--accent-color);
                font-weight: 500;
                font-size: 15px;
                line-height: 18px;
                color: #FFFFFF;
                height: 42px;
                transition: all 200ms;
                border-radius: 8px;
                margin-top: auto;
                z-index: 2;

                &:hover {
                    opacity: 0.8;
                    color: #fff;
                }
            }
        }
    }

    &__category_news {
        display: flex;
        gap: 40px;
    }

    &__category_list {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    &__category:nth-of-type(4) {
        .mainpage__category_list {
            order: -1;
        }
    }

    &__categories_list {
        display: flex;
        gap: 30px;
    }

    &__category_compilation {
        max-width: 33.3%;
    }

    &__category_compilation_news {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}

// 1260+
@media screen and (max-width: 1440px) {
    .mainpage {
        &__content {
            padding: 40px 20px 100px;
            gap: 35px;
        }

        main {
            width: calc(100% - 290px);
        }

        &__category_news {
            gap: 30px;
        }
    }
}

// 980+
@media screen and (max-width: 1260px) {
    .mainpage {
        &__contract {
            display: block;
            max-width: 400px;
            margin-bottom: 20px;
            &:nth-child(2) {
                margin-bottom: 10px;
            }
        }

        main {
            width: 100%;
        }

        &__timeline {
            flex-direction: column;
            gap: 0;

            .mainpage__type_news_content {
                display: none;
            }

            .timeline {
                display: block;
            }
        }

        &__news {
            .timeline {
                display: none;
            }

            .mainpage__type_news_content {
                display: flex;
            }
        }

        &__type_switch {
            margin-bottom: 40px;
            width: 100%;
            display: flex;
            gap: 30px;
            border-bottom: 1px solid var(--line-color);

            &_btn {
                h1 {
                    color: var(--label-color);

                    @media screen and (max-width: 400px) {
                        font-size: 28px;
                    }
                }

                padding-bottom: 10px;
                transition: all 200ms;
                border-bottom: 3px solid #fff;

                &_active {
                    h1 {
                        color: var(--accent-color);
                    }

                    border-bottom: 3px solid var(--accent-color);
                }
            }
        }
    }
}

// 768+
@media screen and (max-width: 980px) {
    .mainpage {
        &__main_slider {
            padding-bottom: 30px;
        }

        &__main_prev {
            position: absolute;
            top: calc(100% - 55px);
            left: 0;
            transform: translateY(0) rotate(180deg) !important;
            z-index: 2;
        }

        &__main_next {
            position: absolute;
            top: calc(100% - 55px);
            right: calc(100% - 100px);
            transform: translateY(0);
            z-index: 2;
        }

        &__content {
            padding: 40px 15px 100px;
        }

        &__category_news {
            flex-direction: column;
        }

        &__category:nth-of-type(4) {
            .mainpage__category_list {
                order: 0;
            }
        }

        &__categories_list {
            flex-direction: column;
        }

        &__category_compilation {
            max-width: none;
        }

        &__rr {
            &_card {
                padding: 25px;
            }
        }
    }
}

// 640+
@media screen and (max-width: 768px) {
    .mainpage {
        &__rr {
            flex-direction: column;

            &_card {
                width: 100%;
                height: 240px;

                &_text {
                    max-width: 65%;
                }
            }
        }

        & .timeline__wrapper {
            margin-top: -20px;
        }
    }
}

.mainage_widget {
    display: none;
    gap: 20px;
    align-items: center;
    @include text-regular;
    margin-bottom: 20px;

    @media screen and (max-width: 785px) {
        display: flex;
    }
}

// 320+
@media screen and (max-width: 640px) {
    .mainpage__type_switch_btn {
        h1 {
            font-size: 24px;
        }
    }

    .mainpage {
        &__type_news_content {
            gap: 30px;
        }

        &__content {
            padding: 20px 15px 100px;
        }

        &__bordered_block {
            padding-bottom: 30px;
        }

        &__category_news {
            flex-direction: column;
        }

        &__category:nth-of-type(4) {
            .mainpage__category_list {
                order: 0;
            }
        }

        &__categories_list {
            flex-direction: column;
        }

        &__category_compilation_news {
            gap: 20px;
        }

        .economy_mobile {
            display: block;
        }

        .economy_decktop {
            display: none;
        }
    }
}

// 320+
@media screen and (max-width: 480px) {
    .mainpage {
        &__rr {
            &_card {
                padding: 20px;

                &_text {
                    max-width: 100%;
                }
            }
        }
    }
}

.economy_mobile {
    display: none;
}