.rrt {
    width: 100%;
    scroll-margin: 130px;
    &__preview {
        width: 100%;
        height: 289px;
        display: flex;
        justify-content: center;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        &_bg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            z-index: -1;
            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    &__title {
        width: fit-content;
        padding: 0 32px;
        height: 92px;
        display: flex;
        gap: 16px;
        justify-content: center;
        align-items: center;
        background: #EFF2FF;
        border-radius: 12px;
        margin-top: 50px;
        &_text {
            font-weight: 700;
            color: #5B99FC;
            text-align: center;
        }
        &_icon {
            width: 60px;
            height: 60px;
        }
    }
}
html {
    scroll-behavior: smooth;
}

@media screen and (max-width: 768px) {
    .rrt {
        &__preview {
            padding: 15px;
            align-items: center;
        }
        &__title {
            margin-top: 0;  
            height: fit-content;
            padding-top: 10px;
            padding-bottom: 15px;
            gap: 8px;
            flex-direction: column;
            &_icon {
                width: 40px;
                height: 40px;
            }
        }
    }
}