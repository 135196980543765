@import '../../vars.scss';
@import '../../fonts.scss';

.aboutus{
    &__container{
        max-width: 930px;
        margin-top: 40px;
        margin-bottom: 100px;
    }
    &__title{
    margin-bottom: 20px;    
    }
    &__subtitle{
        margin-bottom: 20px;    
    }
    &__text{
        margin-top: 20px;
        margin-bottom: 20px; 
        @include text-regular;  
    }
    &__text:last-child{
        margin-bottom: 0px;    
    }
}

.about_wrapper {
    & .article {
        &__content {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 40px 0 100px;
            padding: 0 30px;
            main {
                margin: 0 auto;
                width: auto !important;
            }
        }
        &__container {
            width: auto;
            margin-right: 0;
            max-width: 930px;
        }
        & article {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding-bottom: 40px;
            border-bottom: 1px solid var(--line-color);
            word-break: break-word;
        }
        &__title {
            margin-bottom: 15px;
        }
        &__slider {
            display: flex;
            position: relative;
            .swiper-slide {
                width: fit-content;
            }
            &_nav_btn {
                @include clickable;
                cursor: pointer;
                width: 44px;
                height: 44px;
                border-radius: 8px;
                flex-shrink: 0;
                position: absolute;
                top: calc(50% - 32px - 22px);
                left: calc(100% - 22px);
                z-index: 10;

                &_prev {
                    transform: rotate(180deg);
                    left: -22px;
                }

                &.swiper-button-disabled {
                    path {
                        fill: var(--label-color)
                    }
                    background: var(--tag-search-bg-color);
                    transition: all 200ms;
                    color: var(--text-color);
                    &:hover,
                    &:active {
                        background: var(--tag-search-bg-color);
                        opacity: 1;
                        & path {
                            fill: var(--label-color)
                        }
                    }
                    &:focus {
                        background: var(--tag-search-bg-color);
                        border: none;
                        & path {
                            fill: var(--label-color)
                        }
                    }
                }
            }
        }
        &__video {
            width: 100%;
            border-radius: 12px;
            height: fit-content;
            overflow: hidden;
            cursor: pointer;
            & figure .img-container {
                position: relative;
            }
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &_btn {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                background: linear-gradient(0deg, rgba(34, 34, 34, 0.4), rgba(34, 34, 34, 0.4));
                display: flex;
                align-items: center;
                justify-content: center;   
                transition: all 200ms;
                z-index: 2;
                border-radius: 12px;
                &:hover {
                    opacity: 0.8;
                }         
            }
        }
        &__paragraph {
            @include text-regular;
        }
        &__link {
            @include bg-link;
        }
        &__text {
            &_italic {
                @include text-italic;
            }
            &_strong {
                @include text-medium;
            }
            &_underlined {
                text-decoration: underline;
            }
            &_link {
                color: var(--accent-color);
                transition: all 200ms;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
        &__colored {
            padding: 24px;
            background: var(--tag-search-bg-color);
            border-radius: 12px;
        }
        &__quote {
            padding: 24px;
            background: var(--tag-search-bg-color);
            border-radius: 12px;
            .article__author {
                margin-top: 8px;
            }
        }
        &__author {
            display: flex;
            gap:8px;
            align-items: center;
            cursor: pointer;
            transition: all 200ms;
            &:hover {
                opacity: 0.8;
            }
            &_photo {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                overflow: hidden;
                flex-shrink: 0;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &_name {
                @include label-big;
                color: var(--text-color)
            }
        }
        &__numbered_list {
            list-style: none;
            counter-reset: li;
            @include text-regular;
            li {
                margin-bottom: 6px;
                padding-left: 6px;
                &:last-of-type {
                    margin-bottom: 0;
                }
                &:before {
                    counter-increment: li;
                    content: counters(li,".") ". ";
                    margin-right: 6px;
                }
            }
            ol {
                margin-left: 20px;
                list-style: none;
                counter-reset: li;
                margin-top: 6px;
                li {
                    margin-bottom: 6px;
                    padding-left: 6px;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    &:before {
                        counter-increment: li;
                        content: counters(li,".") ". ";
                        margin-right: 6px;
                    }
                }
                ol {
                    margin-left: 35px;
                    list-style: none;
                    counter-reset: li;
                }
            }
        }

        &__bulleted_list {
            list-style-type: disc;
            @include text-regular;
            list-style-position: inside;
            li {
                margin-bottom: 6px;
                padding-left: 6px;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            ul {
                list-style-type: circle;
                margin-top: 6px;
                margin-left: 40px;
            }
        }
    }
}

ul {
    margin-bottom: 0;
}

// 1260+
@media screen and (max-width: 1440px) {
    .about_wrapper .article {
        &__content {
            padding: 0 20px;
            justify-content: space-evenly;
            gap: 60px;
            & main {
            }
        }
        &__wrapper {
            gap: 60px;
            margin-left: auto;
        }
    }
}

// 980+
@media screen and (max-width: 1260px) {
    .about_wrapper .article {
        main {
            width: 100%;
        }
        &__wrapper {
            justify-content: flex-end;
        }
        &__socials {
            ul {
                flex-direction: column;
                gap: 12px;
                width: 100%;
                align-items: flex-end;
            }
        }
        &__container {
            // margin-right: calc(50vw - 300px - 20px);
        }
    }
}

// 640+
@media screen and (max-width: 980px) {
    .about_wrapper .article {
        &__content {
            padding: 0 15px;
        }
        &__wrapper {
            gap: 40px;
            flex-direction: column;
        }
        &__socials {
            margin-left: 0;
            &_title {
                margin-bottom: 0;
            }
            &_content {
                display: flex;
                align-items: center;
                gap: 12px;
            }
            ul {
                margin-left: 0;
                flex-direction: row;
                margin-bottom: 0;
            }            
        }
        &__container {
            margin-right: 0;
            width: 100%;
            max-width: none;
            article {
                width: 100%;
            }
        }
        &__slider {
            &_nav_btn {
                left: calc(100% - 64px);
                &_prev {
                    left: 20px;
                }
            }
        }
    }
}

// 320+
@media screen and (max-width: 640px) {
    .about_wrapper .article {
        &__socials {
            ul {
                gap: 8px;
                li a,
                li button {
                    width: 30px;
                    height: 30px;
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
            &_copy_helper {
                margin-top: 0;
                svg { 
                    width: 150px;
                }
            } 
        }
        &__views {
            svg {
                width: 16px;
                height: 16px;
            }
        }
        &__quote, 
        &__colored {
            padding: 16px;
        }
        &__author {
            &_photo {
                width: 40px;
                height: 40px;
            }
        }
        &__slider {
            &_nav_btn {
                width: 30px;
                height: 30px;
                top: calc(50% - 40px);
                left: calc(100% - 45px);
                &_prev {
                    left: 15px;
                }
            }
        }
    }
}