@import '../../../fonts.scss';
.rrl {
    width: 100%;
    background: linear-gradient(92.81deg, #418AFF 0%, #77CEFF 100%);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    padding: 40px 15px;
    &__links {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
        margin: 40px 0 0;
        &_el {
            &_link {
                display: block;
                width: 60px;
                height: 60px;
                transition: all 200ms;
                cursor: pointer;
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
    &__title {
        font-weight: 700;
        text-align: center;
        color: #FFFFFF;
        margin-top: 33px;
    }
    &__text {
        @include text-regular;
        font-weight: 400;
        text-align: center;
        color: #FFFFFF;
        margin-top: 12px;
        max-width: 900px;
    }
}

@media screen and (max-width: 768px) {
    .rrl {
        width: 100%;
        height: auto;
        &__links {
            gap: 15px;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 auto;
            padding: 0 30px;
            &_el {
                &_link {
                    width: 40px;
                    height: 40px;
                }
            }
        }
        &__title {
            margin-top: 25px;
        }
        &__text {
            margin-top: 12px;
        }
    }
}