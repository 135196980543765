@import '../../../vars.scss';
@import '../../../fonts.scss';

.rate {
    max-width: 1440px;
    margin: 0 auto;
    padding: 24px 30px 100px;
    &__cards {
        width: 100%;
        margin: 0 auto;
        margin-top: -49px;
    }
    &__cards {
        width: calc(100% - 100px);
        &_swiper {
            width: calc(100% + 30px);
            margin-left: -15px;
            padding: 0 15px;
            height: 374px;
            display: flex;
            & .swiper-slide {
                width: fit-content;
            }
            &_card {
                width: 412px;
                height: 347px;
                padding: 24px;
                background: #FFFFFF;
                border: 1px solid #F2F2F2;
                box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                position: relative;
                &_image {
                    height: 55px;
                    width: 100%;
                    & img {
                        object-fit: contain;
                        width: auto;
                    }
                    & picture {
                        width: auto;
                    }
                    display: flex;
                    gap: 10px;
                    justify-content: center;
                }
                &_year {
                    // margin-left: auto;
                    position: absolute;
                    right: 24px;
                    top: 24px;
                }
                &_text {
                    @include text-regular;
                    margin-top: 16px;
                    max-height: 220px;
                    overflow-y: auto;
                    scrollbar-color: transparent var(--accent-bg-color);
                    scrollbar-width: 8px;
                    &::-webkit-scrollbar {
                        width: 8px;
                    }
                    &::-webkit-scrollbar-track {
                        background: transparent;
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: var(--accent-bg-color);
                        border-radius: 8px;
                    }
                    & p {
                        font-size: 14px;
                    }
                }
                &_link {
                    @include tag;
                    @include clickable;
                    color: var(--dark-label-color);
                    height: 36px;
                    border: 1px solid #D7D7D7;
                    border-radius: 60px;
                    background: none;
                    width: fit-content;
                    margin: 0 auto;
                    padding: 0 15px;
                    margin-top: auto;
                    &.div:hover,
                    &.div:active,
                    &.div:focus {
                        background: none;
                        color: var(--dark-label-color);
                        opacity: 1;
                        border-color: #D7D7D7;
                    }
                    display: none;
                }
            }
        }
        &_nav {
            display: flex;
            gap: 8px;
            margin: 0 auto;
            // margin-top: 24px;
            width: fit-content
        }
    }
    
}
.rr__list {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    gap: 80px;
}

@media screen and (max-width: 768px) {
    .rr__list {
        gap: 60px;
        margin-top: 60px;
    }
    .rate {
        padding: 33px 15px 100px;
        &__cards {
            width: 100%;
            margin: 0 auto;
            margin-top: 20px;
        }
    }    
}

@media screen and (max-width: 640px) {
    .rate {
        &__cards {
            &_swiper {
                &_card {
                    width: calc(100vw - 30px);
                    max-width: 302px;
                }
            }
        }
    }    
}