@import '../../fonts.scss';
@import '../../vars.scss';

.preloader {
    @include text-regular;
    margin-top: 100px;
    font-size: 48px;
    width: 100%;
    text-align: center;
    color: var(--accent-color);
}