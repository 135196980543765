@import '../../fonts.scss';

.search_form {
    width: 300px;
    height: 44px;
    margin-right: 20px;

    & form {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;

        & input {
            width: 100%;
            height: 100%;
            padding: 12px 50px 12px 20px;
            background: var(--tag-search-bg-color);
            border: 1px solid var(--tag-search-bg-color);
            border-radius: 8px;
            transition: all 200ms;

            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: var(--text-color);

            &::placeholder {
                color: var(--label-color)
            }

            &:focus {
                border: 1px solid var(--accent-color)
            }
        }

        &:hover input {
            border: 1px solid var(--line-color);

            &::placeholder {
                color: var(--line-color)
            }

            &:focus {
                border: 1px solid var(--accent-color)
            }
        }

        & button {
            width: 20px;
            height: 20px;
            position: absolute;
            right: 20px;
            @include link;
        }
    }

    &__live_news {
        background: #FFFFFF;
        border: 1px solid var(--label-color);
        border-radius: 8px;
        overflow: hidden;
        width: 370px;
        position: fixed;
        margin-top: 4px;

        &_list {
            padding: 20px 17px 0;
            display: flex;
            flex-direction: column;
            gap: 12px;

            & .card {
                padding-bottom: 12px;
            }
        }

        &_btn {
            width: 100%;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include text-regular;
            color: #fff;
            background-color: var(--accent-color);
            transition: all 200ms;
            margin-top: 12px;

            &:hover {
                opacity: 0.8;
                color: #fff;
            }
        }
    }

    &.large {
        width: 770px;
        height: 72px;
        margin-right: 0;

        & form {
            & input {
                padding: 16px 85px 16px 32px;
                font-size: 32px;
                line-height: 40px;
            }

            & button {
                width: 36.67px;
                height: 36.67px;

                svg {
                    width: 36.67px;
                    height: 36.67px;
                }

                position: absolute;
                right: 33px;
                @include link;
            }
        }

        & .search_form__live_news {
            position: absolute;
            width: 770px;
        }
    }
}

@media screen and (max-width: 979px) {
    .search_form.large {
        width: 600px;

        & .search_form__live_news {
            display: block;
            width: 600px;
        }
    }
}

@media screen and (max-width: 1125px) {
    .search_form {
        width: 44px;
        margin-right: 12px;

        & form {
            input {
                padding: 0;
            }

            input::placeholder {
                opacity: 0;
            }

            button {
                right: inherit;
                width: 44px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__live_news {
            display: none;
        }
    }
}

// 320+
@media screen and (max-width: 640px) {
    .search_form {
        width: 44px;
        margin-right: 12px;

        & form {
            input {
                padding: 0;
            }

            input::placeholder {
                opacity: 0;
            }

            button {
                right: inherit;
                width: 44px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__live_news {
            display: none;
        }

        &.large {
            width: 290px;
            height: 54px;

            & form {
                & input {
                    padding: 12px 41px 12px 20px;
                    font-size: 20px;
                    line-height: 30px;

                    &::placeholder {
                        opacity: 1;
                    }
                }

                & button {
                    width: 27.75px;
                    height: 27.75px;

                    svg {
                        width: 27.75px;
                        height: 27.75px;
                    }
                }
            }

            & .search_form__live_news {
                width: 290px;
            }
        }
    }
}