@import '../../../fonts.scss';

.big_viewer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000080;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    padding: 0 20px;
}

.big_viewer_content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-end;
}

.big_viewer_content_close {
    margin-right: -20px;
    cursor: pointer;
    transition: all 200ms;
    @media screen and (max-width: 640px) {
        margin-right: -10px;
    }
}
.big_viewer_content_close:hover {
    opacity: 0.7;
}

.big_viewer_content_image {
    border-radius: 10px;
    overflow: hidden;
    max-width: 1000px;
}

.search {
    &__images {
        display: flex;
        gap: 20px;
        @media screen and (max-width: 640px) {
            // flex-direction: column;
        }
    }
    &__image {
        width: 400px;
        aspect-ratio: 13/9;
        border-radius: 10px;
        overflow: hidden;
        margin: -10px 0 20px 0;
        max-width: 100%;
        cursor: pointer;
        transition: all 200ms;
        position: relative;
        &:hover &_icon {
            opacity: 1;
        }
        &_icon {
            opacity: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #0000005f;
            & svg {
                width: 44px;
                height: 44px;
            }
            transition: all 200ms;
        }
    }
    &__content {
        display: flex;
        gap: 40px;
        padding: 40px 30px 100px;
        width: 100%;
        main {
            width: 100%;
            flex-shrink: 1;
        }
    }
    &__category {
        margin-bottom: 20px;
    }
    &__subtitle {
        @include text-regular;
        margin-bottom: 30px;
        max-width: 80%;
        opacity: 0.9;
    }
    &__result {
        display: flex;
        flex-direction: column;
        gap: 40px;
        // width: 1050px;
    }

    &__author {
        display: flex;
        gap: 20px;
        align-items: center;
        margin-bottom: 20px;

        &_photo {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            overflow: hidden;
            flex: 0 0 auto;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &_name {
            margin-bottom: 4px;
            word-break: break-word;
        }
        &_info {
            @include text-regular;
        }
    }

    &__zero {
        width: 100%;
        min-width: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        &_text {
            font-weight: 500;
            font-size: 32px;
            line-height: 50px;
            text-align: center;
            color: var(--dark-label-color);
            max-width: 650px;
        }
    }

    &__form {
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--line-color);
        margin-bottom: 40px;
        position: relative;

        &_input_text {
            padding: 0 90px 0 32px;
            width: 100%;
            height: 72px;
            background: var(--tag-search-bg-color);
            border-radius: 8px;
            font-weight: 500;
            font-size: 32px;
            line-height: 1;
            color: var(--text-color);
            margin-bottom: 20px;
            border: 1px solid var(--tag-search-bg-color);
            transition: all 200ms;
            &::placeholder {
                font-weight: 500;
                font-size: 32px;
                line-height: 1;
                color: var(--label-color);
            }
            &:hover {
                border: 1px solid var(--line-color);
                &::placeholder {
                    color: var(--line-color)
                }
            }
            &:active {
                border: 1px solid var(--line-color);
                &::placeholder {
                    color: var(--line-color)
                }
                opacity: 0.5;
                .search__form_submit {
                    opacity: 0.5;
                }
            }
            &:focus {
                border: 1px solid var(--accent-color);
            }
        }
        &_submit {
            position: absolute;
            top: 16px;
            right: 32px;
            @include link;
        }

        &_extra_inputs {
            display: flex;
            gap: 20px;
            margin-bottom: 20px;

            & input {
                width: 230px;
                height: 44px;
                background: #FFFFFF;
                border: 1px solid var(--line-color);
                border-radius: 8px;
            }
        }

        &_result_num {
            @include label-big;
        }
    }
}

// 1260+
@media screen and (max-width: 1440px) {
    .search {
        &__content {
            padding: 40px 20px 100px;
        }
    }
}

// 980+
@media screen and (max-width: 1260px) {
    .search {
        &__content {
            padding: 40px 20px 100px;
        }
    }
}

// 640+
@media screen and (max-width: 980px) {
    .search {
        &__content {
            padding: 40px 15px 100px;
        }
    }
}

// 320+
@media screen and (max-width: 640px) {
    .search {
        &__subtitle {
            @include text-regular;
            max-width: 100%;
        }
        & .card.medium {
            .card {
                &__photo {
                    width: 100%;
                    padding-top: 50%;
                    height: auto;
                    position: relative;
                    img,
                    video {
                        position: absolute;
                        top: 0;
                    }
                }
            } 
        }
        &__form {
            &_extra_inputs {
                flex-direction: column;
                gap: 12px;
                width: fit-content;
            }
            &_input_text {
                height: 54px;
                padding: 0 65px 0 20px;
                font-size: 20px;
                line-height: 30px;
                &::placeholder {
                    font-size: 18px;
                    line-height: 30px;
                }
                & ~ button {
                    top: 15px;
                    right: 20px;
                    & svg {
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }
        &__author {
            width: fit-content;
            flex-direction: column;
            align-items: flex-start;
            gap: 6px;
            &_photo {
                width: 80px;
                height: 80px;
            }
        }
        &__zero {
            &_img {
                width: 211px;
                height: 170px;
            }
            &_text {
                font-size: 20px;
                line-height: 30px;
                max-width: 400px;
            }
        }
    }
}